import aave from '/images/protocols/aave.svg'
import agave from '/images/protocols/agave.png'
import ajna from '/images/protocols/ajna.svg'
import angle from '/images/protocols/angle.svg'
import ankr from '/images/protocols/ankr.svg'
import arcadia from '/images/protocols/arcadia.png'
import backed from '/images/protocols/backed.svg'
import coinbase from '/images/protocols/coinbase.svg'
import compound from '/images/protocols/compound.svg'
import ethena from '/images/protocols/ethena.png'
import etherfi from '/images/protocols/etherfi.svg'
import ethx from '/images/protocols/ethx.png'
import euler from '/images/protocols/euler.png'
import frax from '/images/protocols/frax.svg'
import gearbox from '/images/protocols/gearbox.png'
import hop from '/images/protocols/hop.svg'
import instadapp from '/images/protocols/instadapp.svg'
import inverse from '/images/protocols/inverse.png'
import lido from '/images/protocols/lido.svg'
import maker from '/images/protocols/maker.svg'
import maple from '/images/protocols/maple.png'
import matrixdock from '/images/protocols/matrixdock.webp'
import mev from '/images/protocols/mev.svg'
import moonwell from '/images/protocols/moonwell.png'
import morpho from '/images/protocols/morpho.png'
import notional from '/images/protocols/notional.png'
import oeth from '/images/protocols/oeth.svg'
import ondo from '/images/protocols/ondo.png'
import openeden from '/images/protocols/openeden.svg'
import overnight from '/images/protocols/overnight.png'
import pooltogether from '/images/protocols/pooltogether.svg'
import rocketPool from '/images/protocols/rocketPool.png'
import seamless from '/images/protocols/seamless.png'
import silo from '/images/protocols/silo.svg'
import spark from '/images/protocols/spark.svg'
import stakewise from '/images/protocols/stakewise.svg'
import stargate from '/images/protocols/stargate.svg'
import swell from '/images/protocols/swell.svg'
import truefi from '/images/protocols/truefi.svg'
import yearn from '/images/protocols/yearn.svg'
import yeth from '/images/protocols/yeth.svg'
import usdm from '/images/tokens/usdm.svg'

export function getVaultProtocolImage(protocol: string) {
  switch (protocol.toLowerCase()) {
    case 'aave':
      return aave
    case 'ankr':
      return ankr
    case 'ajna':
      return ajna
    case 'arcadia':
      return arcadia
    case 'coinbase':
      return coinbase
    case 'yearn':
      return yearn
    case 'compound':
      return compound
    case 'ethx':
      return ethx
    case 'ethena':
      return ethena
    case 'euler':
      return euler
    case 'frax':
      return frax
    case 'instadapp':
      return instadapp
    case 'inverse finance':
      return inverse
    case 'lido':
      return lido
    case 'truefi':
      return truefi
    case 'maker':
      return maker
    case 'maple':
      return maple
    case 'morpho':
      return morpho
    case 'open eden':
      return openeden
    case 'overnight finance':
      return overnight
    case 'pooltogether':
      return pooltogether
    case 'rocket pool':
      return rocketPool
    case 'spark':
      return spark
    case 'swell':
      return swell
    case 'oeth':
      return oeth
    case 'hop':
      return hop
    case 'stakewise':
      return stakewise
    case 'stargate':
      return stargate
    case 'yeth':
      return yeth
    case 'agave':
      return agave
    case 'angle':
      return angle
    case 'mountain':
      return usdm
    case 'silo':
      return silo
    case 'matrixdock':
      return matrixdock
    case 'mev':
      return mev
    case 'ondo':
      return ondo
    case 'backed':
      return backed
    case 'ether.fi':
      return etherfi
    case 'seamless':
      return seamless
    case 'notional':
      return notional
    case 'gearbox':
      return gearbox
    case 'moonwell':
      return moonwell
    default:
  }
}
