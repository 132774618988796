import { cn } from '@/utils/cn'
import { DetailsCharts } from './DetailsCharts'
import { DetailsStatistics } from './DetailsStatistics'
import { VaultExpandedStatistics } from './VaultExpandedStatistics'

import { Tile } from '@/components/atoms/Tile'
import { PreviewYieldSource } from '@/pages/VaultPages/PreviewPage/components'
import type { ApyModes, BackendVaultDetailed, VaultForOptimizer } from '@/types'

interface VaultDetailsProps {
  vault: VaultForOptimizer
  vaultData: BackendVaultDetailed | undefined
  period: number | undefined
  isHighTvlImpact: boolean
  apyMode: ApyModes
  isTablet: boolean
}

export const VaultDetails = ({ vault, vaultData, ...props }: VaultDetailsProps) => {
  if (!vault || !vaultData) {
    return <Fallback />
  }
  return <Result {...props} vault={vault} vaultData={vaultData} />
}

const wrapperStyles = cn('overflow-hidden transition-all')
const detailsStyles = cn('flex flex-col gap-y-6 overflow-hidden px-4 pb-4 md:px-6 md:pb-6')

const Fallback = () => {
  return (
    <div className={wrapperStyles}>
      <div className={detailsStyles}>
        <p>Vault details are loading...</p>
      </div>
    </div>
  )
}

interface ResultProps extends Omit<VaultDetailsProps, 'vaultData'> {
  vaultData: BackendVaultDetailed
}

const Result = ({ vault, vaultData, period, isHighTvlImpact, apyMode, isTablet }: ResultProps) => {
  const { yieldSource } = vaultData

  return (
    <div className={wrapperStyles}>
      <div className={detailsStyles}>
        <div className="flex flex-wrap gap-4 [&_[data-role=tile]]:flex-grow">
          {isTablet && <VaultExpandedStatistics vault={vault} period={period} isHighTvlImpact={isHighTvlImpact} />}
          <DetailsStatistics vaultData={vaultData} />
        </div>
        {yieldSource && (
          <Tile spacing="medium" className="bg-beige">
            <PreviewYieldSource vault={vaultData} />
          </Tile>
        )}
        <DetailsCharts vault={vaultData} apyMode={apyMode} />
      </div>
    </div>
  )
}
