import { type ReactNode, useMemo, useState } from 'react'
import { Area, CartesianGrid, ComposedChart, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { ChartLegend } from '../ChartLegend'
import { ApyChartTooltip } from './ApyChartTooltip'
import { ApyChartTransparentTooltip } from './ApyChartTransparentTooltip'

import { Button } from '@/components/atoms/Button'
import { ChartContainer } from '@/components/atoms/Charts/ChartContainer'
import { ChartWatermark, type ChartWatermarkType } from '@/components/atoms/Charts/ChartWatermark'
import { ZoomOutButton } from '@/components/atoms/Charts/ZoomOutButton'
import { InvisibleIcon, VisibleIcon } from '@/components/atoms/Icons'
import { useChartZoom } from '@/hooks'
import { useChartZoomedIn } from '@/hooks/charts/useChartZoomedIn'
import { useOnMouseUp } from '@/hooks/charts/useOnMouseUp'
import type { ApyChartData, ApyChartDescriptorKey, ApyModes } from '@/types'
import {
  formatNumber,
  getApyChartDescriptorStackId,
  getChartBaseApyItemDescriptor,
  getChartDateLabel,
  getChartRewardsApyItemDescriptor,
} from '@/utils'
import { isPointInDomain } from '@/utils/chartUtils/isPointInDomain'
import { cn } from '@/utils/cn'
import { DAY_IN_SECONDS, HOUR_IN_SECONDS, truncateChartData } from '@vaultsfyi/common'

interface ApyChartProps extends ChartWatermarkType {
  chartData: ApyChartData[]
  hideLegend?: boolean
  hideXAxis?: boolean
  apyMode: ApyModes
  tooltipView?: 'default' | 'transparent'
  zoomable: boolean
  className?: string
}

type ChartDescriptor = {
  key: ApyChartDescriptorKey
  stackId: number | undefined
  name: string
  colors: string[]
  isVisible: boolean
  button?: ReactNode
}

type TooltipView = 'default' | 'transparent'

export const ApyChart = ({
  chartData,
  hideLegend,
  hideXAxis,
  apyMode,
  tooltipView,
  zoomable,
  variant,
  className,
}: ApyChartProps) => {
  const [isRawApyVisible, setRawApyVisible] = useState<boolean>(false)
  const [isRewardsVisible, setRewardsVisible] = useState<boolean>(false)

  const rangeDescriptor: ChartDescriptor = useMemo(
    () => ({
      key: 'rangeApy',
      stackId: getApyChartDescriptorStackId('rangeApy'),
      name: getChartBaseApyItemDescriptor(apyMode),
      colors: getChartColors('rangeApy'),
      isVisible: true,
    }),
    [apyMode]
  )

  const rewardsDescriptor: ChartDescriptor = useMemo(() => {
    const handleRewardsApyChartVisible = () => {
      setRewardsVisible((prevState) => !prevState)
    }
    return {
      key: 'rewardsApy',
      stackId: getApyChartDescriptorStackId('rewardsApy'),
      name: getChartRewardsApyItemDescriptor(apyMode),
      colors: getChartColors('rewardsApy'),
      button: (
        <Button variant="icon" onClick={handleRewardsApyChartVisible}>
          {isRewardsVisible ? <VisibleIcon /> : <InvisibleIcon />}
        </Button>
      ),
      isVisible: isRewardsVisible,
    }
  }, [isRewardsVisible])

  const rawDescriptor: ChartDescriptor = useMemo(() => {
    const handleRawApyChartVisible = () => {
      setRawApyVisible((prevState) => !prevState)
    }
    return {
      key: 'rawApy',
      stackId: getApyChartDescriptorStackId('rawApy'),
      name: 'Raw',
      colors: getChartColors('rawApy'),
      button: (
        <Button variant="icon" onClick={handleRawApyChartVisible}>
          {isRawApyVisible ? <VisibleIcon /> : <InvisibleIcon />}
        </Button>
      ),
      isVisible: isRawApyVisible,
    }
  }, [isRawApyVisible])

  const chartDescriptors = [rangeDescriptor, rewardsDescriptor, rawDescriptor]

  const {
    isZoomEnabled,
    state,
    allowDataOverflow,
    xAxisDomain,
    yAxisDomain,
    onChartMouseDown,
    onChartMouseMove,
    onChartMouseUp,
    onChartMouseLeave,
    zoomOut,
  } = useChartZoom({
    initialData: chartData as unknown as Record<string, number>[],
    dataKeys: chartDescriptors.filter(({ isVisible }) => isVisible).map(({ key }) => key),
    zoomable,
  })

  const truncChartData = useMemo(
    () => ({
      lowRes: truncateChartData(chartData, 'date', DAY_IN_SECONDS).filter((data) => isPointInDomain(data, xAxisDomain)),
      highRes: truncateChartData(chartData, 'date', HOUR_IN_SECONDS).filter((data) =>
        isPointInDomain(data, xAxisDomain)
      ),
    }),
    [chartData.length, xAxisDomain]
  )
  const zoomedIn = useChartZoomedIn(chartData, xAxisDomain)

  const [mouseOut, setMouseOut] = useState(true)
  useOnMouseUp(() => mouseOut && onChartMouseUp(), [mouseOut])

  return (
    <div className="relative flex flex-col">
      <ChartContainer className={cn('h-64 min-h-64', className)}>
        <ResponsiveContainer>
          <ComposedChart
            data={zoomedIn ? truncChartData.highRes : truncChartData.lowRes}
            onMouseDown={onChartMouseDown}
            onMouseMove={onChartMouseMove}
            onMouseUp={onChartMouseUp}
            onMouseEnter={() => setMouseOut(false)}
            onMouseLeave={() => {
              setMouseOut(true)
              onChartMouseLeave()
            }}
            margin={{ top: 8, right: 0, bottom: 0, left: 6 }}
          >
            <defs>
              {chartDescriptors.map(({ key, colors }) => (
                <linearGradient key={key} id={key} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor={colors[0]} stopOpacity={1} />
                  <stop offset="100%" stopColor={colors[1]} stopOpacity={1} />
                </linearGradient>
              ))}
            </defs>
            <CartesianGrid strokeDasharray="3 3" horizontal={false} vertical={!hideXAxis} />
            <XAxis
              dataKey="date"
              scale="time"
              tick={!hideXAxis}
              tickFormatter={getChartDateLabel}
              minTickGap={16}
              tickLine={false}
              height={hideXAxis ? 0 : undefined}
              domain={xAxisDomain}
              allowDataOverflow={allowDataOverflow}
              type={isZoomEnabled ? 'number' : 'category'}
            />
            <YAxis
              tickFormatter={(tick) => `${formatNumber(tick)}%`}
              width={38}
              tickLine={false}
              domain={yAxisDomain}
              allowDataOverflow={allowDataOverflow}
            />
            {getApyChartTooltip(tooltipView, apyMode)}
            {chartDescriptors.map(
              ({ key, stackId, colors, isVisible }) =>
                isVisible && (
                  <Area
                    key={key}
                    stackId={stackId}
                    type="monotone"
                    dataKey={key}
                    stroke={colors[0]}
                    strokeWidth={1.5}
                    fill={`url(#${key})`}
                    fillOpacity={0.5}
                    isAnimationActive={false}
                  />
                )
            )}
            {isZoomEnabled && state.dataKeyAreaLeft && state.dataKeyAreaRight ? (
              <ReferenceArea
                x1={state.dataKeyAreaLeft}
                x2={state.dataKeyAreaRight}
                fill="hsl(var(--gray)/30%)"
                stroke="hsl(var(--gray))"
                ifOverflow="visible"
              />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
        <ChartWatermark variant={variant} />
        {isZoomEnabled && (
          <ZoomOutButton onClick={zoomOut} isVisible={state.left !== 'dataMin' && state.right !== 'dataMax'} />
        )}
      </ChartContainer>
      {!hideLegend && <ChartLegend className="justify-start" legendElements={Object.values(chartDescriptors)} />}
    </div>
  )
}

function getApyChartTooltip(tooltipView: TooltipView = 'default', apyMode: ApyModes) {
  switch (tooltipView) {
    case 'transparent':
      return <Tooltip content={<ApyChartTransparentTooltip apyMode={apyMode} />} position={{ y: -8 }} />
    case 'default':
    default:
      return <Tooltip content={<ApyChartTooltip apyMode={apyMode} />} />
  }
}

function getChartColors(name: string): string[] {
  switch (name) {
    case 'rawApy':
      return ['hsl(var(--chart-peach))', 'hsl(var(--chart-peach)/1%)']
    case 'rangeApy':
      return ['hsl(var(--chart-purple))', 'hsl(var(--chart-purple)/1%)']
    case 'rewardsApy':
      return ['hsl(var(--chart-green))', 'hsl(var(--chart-green)/1%)']
    default:
      return ['hsl(var(--chart-blue))', 'hsl(var(--chart-blue)/1%)']
  }
}
