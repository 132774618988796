import { Label } from '@/components/atoms/Label'
import { Separator } from '@/components/atoms/Separator'
import { Switch } from '@/components/atoms/Switch'
import { Tile, type tileVariants } from '@/components/atoms/Tile'
import { SelectChartPeriod } from '@/components/molecules'
import { ApyHistoryChart, AssetsChart, HolderProportionsChart, TvlHistoryChart } from '@/components/organisms/Charts'
import { trackVaultsListEvents } from '@/pages/VaultsListPage/hooks'
import type { ApyModes, BackendVaultDetailed } from '@/types'
import { type ChartPeriod, DEFAULT_CHART_PERIOD } from '@/utils'
import { composeTvlChartData } from '@/utils/chartUtils/composeTvlChartData'
import { cn } from '@/utils/cn'
import type { VariantProps } from 'class-variance-authority'
import { type HTMLAttributes, useState } from 'react'

interface Props {
  vault: BackendVaultDetailed
  apyMode: ApyModes
  className?: string
}

export const DetailsCharts = ({ vault, apyMode, className }: Props) => {
  const [period, setPeriod] = useState<ChartPeriod>(DEFAULT_CHART_PERIOD)
  const [tvlInNativeAsset, setTvlInNativeAsset] = useState<boolean>(false)

  const composedTvlChartData = composeTvlChartData(vault)

  const handleNativeAsset = (checked: boolean) => {
    trackVaultsListEvents({
      action: 'Native asset toggled',
      label: `Native asset switched ${checked ? 'on' : 'off'}`,
    })
    setTvlInNativeAsset(checked)
  }

  return (
    <div className={cn('grid w-full grid-cols-1 gap-4 md:grid-cols-2', className)}>
      <ChartTitle className="col-span-full py-3">
        <div className="flex flex-wrap items-center justify-end gap-x-4 gap-y-2">
          <Label>
            <Switch aria-label="TVL in native asset" checked={tvlInNativeAsset} onCheckedChange={handleNativeAsset} />
            TVL in native asset
          </Label>
          <Separator orientation="vertical" className="hidden h-8 sm:block" />
          <SelectChartPeriod period={period} setPeriod={setPeriod} />
        </div>
      </ChartTitle>
      <ChartTitle>
        <TvlHistoryChart
          chartData={composedTvlChartData}
          title="TVL History"
          period={period}
          tvlInNativeAsset={tvlInNativeAsset}
          asset={vault.asset}
        />
      </ChartTitle>
      <ChartTitle>
        <ApyHistoryChart vault={vault} apyMode={apyMode} period={period} />
      </ChartTitle>
      <ChartTitle>
        <HolderProportionsChart vault={vault} tvlInNativeAsset={tvlInNativeAsset} />
      </ChartTitle>
      <ChartTitle>
        <AssetsChart vault={vault} tvlInNativeAsset={tvlInNativeAsset} />
      </ChartTitle>
    </div>
  )
}

const ChartTitle = ({ className, ...props }: HTMLAttributes<HTMLDivElement> & VariantProps<typeof tileVariants>) => {
  return <Tile className={cn('bg-white p-3 sm:p-4', className)} variant="secondary" spacing="medium" {...props} />
}
