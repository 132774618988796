import { DepositButton } from '@/components/molecules/DepositButton'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'

interface Props {
  lendLink: string | null
  onClick?: () => void
}

export const DepositStatistic = ({ lendLink, onClick }: Props) => (
  <div>
    <LabeledValueHeader>Vault link</LabeledValueHeader>
    <LabeledValueContent>
      <LabeledValueValue>
        <DepositButton lendLink={lendLink} onClick={onClick} className="w-full" />
      </LabeledValueValue>
    </LabeledValueContent>
  </div>
)
