import { Panel, PanelContentWrapper } from '@/components/atoms/Panel'
import { Separator } from '@/components/atoms/Separator'
import { SelectChartPeriod } from '@/components/molecules'
import { ApySelectGroup } from '@/components/molecules/ApySelectGroup'
import { RewardsSwitch } from '@/components/molecules/RewardsSwitch/RewardsSwitch'
import { APY_MODE_KEY, EXCLUDE_REWARDS_KEY } from '@/constants/stateConstants'
import { useVaults } from '@/hooks'
import { useSearchState } from '@/hooks/useSearchState'
import { type BackendVault, type BackendVaultDetailed, isApyMode } from '@/types'
import { type ChartPeriod, DEFAULT_CHART_PERIOD, isChartPeriod } from '@/utils'
import { composeMultiChartData } from '@/utils/chartUtils/composeMultiChartData'
import { BENCHMARKS } from '@vaultsfyi/common'
import { getVaultKey } from '@vaultsfyi/common'
import { useMemo } from 'react'
import { MultiCharts } from './MultiCharts'
import { MultiChartsFallback } from './MultiChartsFallback'
import { VaultsSelect } from './components/VaultsSelect'

interface ChartExplorerProps {
  vaults: BackendVault[]
}

export function ChartExplorer({ vaults }: ChartExplorerProps) {
  const { updateSearchState, searchState } = useSearchState()
  const apyModeRaw = searchState.get(APY_MODE_KEY) ?? ''
  const apyMode = isApyMode(apyModeRaw) ? apyModeRaw : '7day'
  const excludeRewards = searchState.get(EXCLUDE_REWARDS_KEY) === 'true'
  const periodRaw = searchState.get('period') ?? ''
  const period = isChartPeriod(periodRaw) ? periodRaw : DEFAULT_CHART_PERIOD
  const selectedVaults = searchState.getAll('vaults') ?? []
  const selectedBenchmarks = searchState.getAll('benchmarks') ?? []

  const setPeriod = (value: ChartPeriod) => updateSearchState(['period', value])
  const setSelectedVaults = (value: string[]) => updateSearchState(['vaults', value])
  const setSelectedBenchmarks = (value: string[]) => updateSearchState(['benchmarks', value])

  const fullSelectedBenchmarks = useMemo(
    () => Object.values(BENCHMARKS).filter((benchmark) => selectedBenchmarks.includes(benchmark.name)),
    [selectedBenchmarks]
  )

  const fullBenchmarkVaults = useMemo(() => {
    return fullSelectedBenchmarks.flatMap((benchmark) => benchmark.vaults)
  }, [fullSelectedBenchmarks])

  const fullSelectedVaults = useMemo(
    () => vaults.filter((vault) => selectedVaults.includes(getVaultKey(vault))),
    [selectedVaults]
  )
  const vaultsToFetch = [...fullSelectedVaults, ...fullBenchmarkVaults]
  const selectedVaultsData = useVaults(
    fullSelectedVaults
      ? vaultsToFetch.map((vault) => ({
          address: vault.address,
          chainName: vault.network,
          interval: apyMode,
        }))
      : []
  )
  const filteredVaultData = selectedVaultsData.filter(
    (vault) => vault !== undefined
  ) as unknown as BackendVaultDetailed[]

  const multiChartData = useMemo(
    () => composeMultiChartData(filteredVaultData, fullSelectedBenchmarks),
    [filteredVaultData]
  )

  return (
    <>
      <ApySelectGroup value={apyMode} setValue={(value) => updateSearchState([APY_MODE_KEY, value])} />
      <Panel className="p-4 sm:px-8 sm:py-6">
        <PanelContentWrapper className="gap-4 sm:gap-6">
          <div className="flex flex-col gap-2">
            <VaultsSelect
              vaults={vaults}
              benchmarks={Object.values(BENCHMARKS)}
              selectedBenchmarks={selectedBenchmarks}
              setSelectedBenchmarks={setSelectedBenchmarks}
              selectedVaults={selectedVaults}
              setSelectedVaults={setSelectedVaults}
              name="vault"
            />
            <div className="flex flex-wrap items-center justify-end gap-x-4 gap-y-2">
              <RewardsSwitch />
              <Separator orientation="vertical" className="hidden h-8 sm:block" />
              <SelectChartPeriod period={period} setPeriod={setPeriod} />
            </div>
          </div>
          {filteredVaultData.length > 0 ? (
            <MultiCharts
              vaults={fullSelectedVaults}
              benchmarks={fullSelectedBenchmarks}
              multiChartData={multiChartData}
              apyMode={apyMode}
              excludeRewards={excludeRewards}
              period={period}
            />
          ) : (
            <MultiChartsFallback apyMode={apyMode} />
          )}
        </PanelContentWrapper>
      </Panel>
    </>
  )
}
