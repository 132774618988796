import { Label } from '@/components/atoms/Label'
import { Panel } from '@/components/atoms/Panel'
import { Separator } from '@/components/atoms/Separator'
import { Switch } from '@/components/atoms/Switch'
import { SelectChartPeriod } from '@/components/molecules'
import { ApyHistoryChart, AssetsChart, HolderProportionsChart, TvlHistoryChart } from '@/components/organisms/Charts'
import { trackVaultsListEvents } from '@/pages/VaultsListPage/hooks'
import type { ApyModes, BackendVaultDetailed } from '@/types'
import { type ChartPeriod, DEFAULT_CHART_PERIOD } from '@/utils'
import { composeTvlChartData } from '@/utils/chartUtils/composeTvlChartData'
import { cn } from '@/utils/cn'
import { useState } from 'react'

interface Props {
  vault: BackendVaultDetailed
  apyMode: ApyModes
}

const chartPanelStyles = cn('w-full p-4 sm:p-6')

export const PreviewCharts = ({ vault, apyMode }: Props) => {
  const [period, setPeriod] = useState<ChartPeriod>(DEFAULT_CHART_PERIOD)
  const [tvlInNativeAsset, setTvlInNativeAsset] = useState<boolean>(false)

  const composedTvlChartData = composeTvlChartData(vault)

  const handleNativeAsset = (checked: boolean) => {
    trackVaultsListEvents({
      action: 'Native asset toggled',
      label: `Native asset switched ${checked ? 'on' : 'off'}`,
    })
    setTvlInNativeAsset(checked)
  }

  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
      <Panel className="px-4 py-3 sm:px-6 md:col-span-2">
        <div className="flex flex-wrap items-center justify-end gap-x-4 gap-y-2">
          <Label>
            <Switch aria-label="TVL in native asset" checked={tvlInNativeAsset} onCheckedChange={handleNativeAsset} />
            TVL in native asset
          </Label>
          <Separator orientation="vertical" className="hidden h-8 sm:block" />
          <SelectChartPeriod period={period} setPeriod={setPeriod} />
        </div>
      </Panel>
      <Panel className={chartPanelStyles}>
        <TvlHistoryChart
          chartData={composedTvlChartData}
          period={period}
          title="TVL History"
          tvlInNativeAsset={tvlInNativeAsset}
          asset={vault.asset}
        />
      </Panel>
      <Panel className={chartPanelStyles}>
        <ApyHistoryChart vault={vault} apyMode={apyMode} period={period} />
      </Panel>
      <Panel className={chartPanelStyles}>
        <HolderProportionsChart vault={vault} tvlInNativeAsset={tvlInNativeAsset} />
      </Panel>
      <Panel className={chartPanelStyles}>
        <AssetsChart vault={vault} tvlInNativeAsset={tvlInNativeAsset} />
      </Panel>
    </div>
  )
}
