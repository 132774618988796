import type { Subdomain } from '@/types/Subdomain'
import { SubdomainType } from '@/types/Subdomain'

interface Props {
  subdomain: Subdomain | undefined
}

export const VaultsListHero = ({ subdomain }: Props) => {
  return (
    <div className="mx-auto hidden text-center sm:flex">
      <h1 className="font-normal text-xl leading-5 sm:text-3xl">
        The homepage of {subdomain ? <SubdomainOpportunitiesText subdomain={subdomain} /> : <>onchain</>} yield
      </h1>
    </div>
  )
}

function SubdomainOpportunitiesText({ subdomain }: { subdomain: Subdomain }) {
  const isYearnProtocol = subdomain.type === SubdomainType.Protocol && subdomain.name === 'yearn'

  if (isYearnProtocol) {
    return <>Yearn v3</>
  }

  return <span className="capitalize">{subdomain.name}</span>
}
