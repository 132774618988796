import { sortObjectsByKey } from './sortUtils'

import type { SortBySchema } from '@/pages/VaultsListPage/types'
import type { VaultForList } from '@/types'

export function sortVaults(vaults: VaultForList[], { sortBy, asc }: SortBySchema) {
  switch (sortBy) {
    case 'protocol':
      return sortObjectsByKey(vaults, ['protocolName'], asc)
    case 'network':
      return sortObjectsByKey(vaults, ['network'], asc)
    case 'apy':
      return sortObjectsByKey(vaults, ['apy'], asc)
    case 'tvl':
      return sortObjectsByKey(vaults, ['tvlInUsd'], asc)
    case 'token':
      return sortObjectsByKey(vaults, ['asset', 'symbol'], asc)
    case 'name':
    default:
      return sortObjectsByKey(vaults, ['name'], asc)
  }
}
