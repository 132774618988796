import type { Network } from '@vaultsfyi/common'

import { Tile, type tileVariants } from '@/components/atoms/Tile'
import { DetailsCharts } from '@/components/organisms/VaultItem'
import {
  AddressStatistic,
  CurrentYieldStatistic,
  DepositStatistic,
  HoldersStatistic,
  ProtocolStatistic,
  RewardsStatistic,
  TVLStatistic,
  TagsStatistic,
  TokenPriceStatistic,
} from '@/components/organisms/VaultStatistics'
import { useVault, useVaultTrackingParams } from '@/hooks'
import { PreviewApyWarnings, PreviewYieldSource } from '@/pages/VaultPages/PreviewPage/components'
import type { ApyModes, BackendVaultDetailed, VaultForList } from '@/types'
import type { Subdomain } from '@/types/Subdomain'
import { getVaultUnexpiredWarnings, trackVaultButtonEvent } from '@/utils'
import { cn } from '@/utils/cn'
import type { VariantProps } from 'class-variance-authority'
import type { HTMLAttributes } from 'react'

interface VaultsListRowDetailsProps {
  vault: VaultForList
  apyMode: ApyModes
  subdomain: Subdomain | undefined
}

export const VaultsListRowDetails = ({ vault, apyMode, subdomain }: VaultsListRowDetailsProps) => {
  const vaultData = useVault({
    address: vault.address,
    chainName: vault.network,
    interval: apyMode,
  })

  if (!vault || !vaultData) {
    return <Fallback />
  }
  return <Result vault={vault} vaultData={vaultData} apyMode={apyMode} subdomain={subdomain} />
}

const Fallback = () => {
  return (
    <div className="flex flex-col gap-4 [&_[data-role=tile]]:bg-white">
      <Tile>
        <p>Vault details are loading...</p>
      </Tile>
    </div>
  )
}

interface ResultProps extends VaultsListRowDetailsProps {
  vaultData: BackendVaultDetailed
}

const Result = ({ vaultData, apyMode, subdomain }: ResultProps) => {
  const {
    address,
    network,
    name,
    asset,
    lendLink,
    metadata,
    tags,
    baseApy,
    rewardsApy,
    tvl,
    assetPriceInUsd: tokenPriceWithPrecision,
    holdersCount,
    warnings,
    yieldSource,
  } = vaultData

  const trackingParams = useVaultTrackingParams({
    address,
    network,
    name,
    asset,
    protocolName: vaultData?.metadata.protocol.name,
    liquidity: vaultData?.liquidity,
    tvl: vaultData?.tvl,
  })

  const handleLinkEvent = () => {
    trackVaultButtonEvent({
      event: 'Go Button',
      params: trackingParams,
    })
  }

  const unexpiredWarnings = getVaultUnexpiredWarnings(warnings)

  return (
    <div
      className={cn(
        'grid grid-cols-[repeat(auto-fit,minmax(180px,1fr))] gap-2',
        'sm:flex sm:grid-cols-[unset] sm:flex-wrap sm:gap-4 sm:[&_[data-role=tile]]:flex-grow'
      )}
    >
      <StatisticTile className="col-span-full sm:col-span-[unset]">
        <AddressStatistic address={address} network={network} />
      </StatisticTile>
      <StatisticTile>
        <ProtocolStatistic metadata={metadata} />
      </StatisticTile>
      {tags && subdomain?.name !== 'yearn' && (
        <StatisticTile>
          <TagsStatistic tags={tags} />
        </StatisticTile>
      )}
      <StatisticTile>
        <DepositStatistic lendLink={lendLink} onClick={handleLinkEvent} />
      </StatisticTile>
      <StatisticTile>
        <CurrentYieldStatistic baseApy={baseApy} apyMode={apyMode} />
      </StatisticTile>
      <StatisticTile>
        <RewardsStatistic rewardsApy={rewardsApy} />
      </StatisticTile>
      <StatisticTile>
        <TVLStatistic tvl={tvl} asset={asset} network={network as Network} address={address} />
      </StatisticTile>
      <StatisticTile>
        <TokenPriceStatistic tokenPriceWithPrecision={tokenPriceWithPrecision} />
      </StatisticTile>
      <StatisticTile>
        <HoldersStatistic holders={holdersCount} />
      </StatisticTile>
      {unexpiredWarnings.length > 0 && <PreviewApyWarnings warnings={unexpiredWarnings} />}
      {yieldSource && (
        <StatisticTile className="col-span-full bg-beige" spacing="medium">
          <PreviewYieldSource vault={vaultData} />
        </StatisticTile>
      )}
      <DetailsCharts vault={vaultData} apyMode={apyMode} className="col-span-full gap-2 sm:gap-4" />
    </div>
  )
}

const StatisticTile = ({ className, ...props }: HTMLAttributes<HTMLDivElement> & VariantProps<typeof tileVariants>) => {
  return <Tile className={cn('bg-white p-3 sm:p-4', className)} variant="secondary" spacing="medium" {...props} />
}
