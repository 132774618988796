import { VaultsList } from './components'

import { Page } from '@/components/molecules'
import { usePageTracking } from '@/hooks'

export function Component() {
  usePageTracking()

  return (
    <Page title="Vaults">
      <VaultsList />
    </Page>
  )
}
