import { Button } from '@/components/atoms/Button'
import { Dialog, DialogBody, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from '@/components/atoms/Dialog'
import { Input } from '@/components/atoms/Input'
import { trpcQueryClient } from '@/config/trpc'
import { useState } from 'react'

interface DepositButtonProps {
  lendLink?: string | null
  onClick?: () => void
  className?: string
}

type QueryStatus = 'idle' | 'loading' | 'success' | 'error'

interface QueryResult {
  status: QueryStatus
  message: string
}

export const DepositButton = ({ lendLink, onClick, className }: DepositButtonProps) => {
  const [email, setEmail] = useState('')
  const [result, setResult] = useState<QueryResult>({ status: 'idle', message: '' })

  const submitEmail = async () => {
    setResult({ status: 'loading', message: '' })
    try {
      const result = await trpcQueryClient.addToMailingList.mutate({ email })
      setResult({ status: 'success', message: result.message })
    } catch (e) {
      const error = e as Error
      try {
        const parsedError = JSON.parse(error.message)
        setResult({ status: 'error', message: parsedError[0].message })
      } catch {
        setResult({ status: 'error', message: error.message })
      }
    }
  }
  const disabled = result.status === 'loading' || result.status === 'success'

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button onClick={onClick} variant="primary" className={className}>
          Deposit
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader closable>
          <h2>Deposit</h2>
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col justify-center gap-3 p-3 text-center">
            <p>Want to manage & track your DeFi positions directly through vaults.fyi?</p>
            {result.status !== 'success' && (
              <div className="mx-auto w-fit">
                <Input
                  className="rounded-r-none"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyUp={(e) => e.key === 'Enter' && submitEmail()}
                  disabled={disabled}
                />
                <Button variant="secondary" className="rounded-l-none" onClick={submitEmail} disabled={disabled}>
                  Get early access
                </Button>
              </div>
            )}
            {result.status === 'loading' && <p className="text-textPrimary">Loading...</p>}
            {result.status === 'success' && <p className="font-bold text-green">{`${result.message}!`}</p>}
            {result.status === 'error' && <p className="text-red">{result.message}</p>}
          </div>
        </DialogBody>
        {lendLink && (
          <DialogFooter>
            <Button variant="primary" className="w-full" asChild>
              <a href={lendLink} target="_blank" rel="noopener noreferrer">
                Go to external app
              </a>
            </Button>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  )
}
