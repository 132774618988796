import { useState } from 'react'

import { useVaultTvlImpact } from '@/pages/YieldOptimizerPage/hooks'

import { AccordionContent, AccordionItem } from '@/components/atoms/Accordion'
import { Panel } from '@/components/atoms/Panel'
import {
  HighTvlImpactBadge,
  type InvestmentData,
  VaultApyTile,
  VaultBasicStatistics,
  VaultControls,
  VaultDetails,
  VaultExpandedStatistics,
  VaultItemContent,
  VaultMainInfo,
  VaultStatisticsWrapper,
} from '@/components/organisms/VaultItem'
import { useVault, useVaultTrackingParams, useWidthBreakpoint } from '@/hooks'
import { Breakpoints } from '@/styles/breakpoints'
import type { ApyModes, VaultForOptimizer } from '@/types'
import { trackVaultButtonEvent } from '@/utils'

interface VaultItemProps {
  vault: VaultForOptimizer
  topItem?: boolean
  apyMode: ApyModes
}

export const OptimizerVaultItem = ({ vault, topItem, apyMode, amount, period }: VaultItemProps & InvestmentData) => {
  const { address, name, network, asset, rawYieldInToken, lendLink, tags } = vault

  const isTablet = useWidthBreakpoint(Breakpoints.tablet)
  const [isDetailsOpen, setDetailsOpen] = useState(false)

  const vaultData = useVault({
    address,
    chainName: network,
    interval: apyMode,
  })

  const { isHighTvlImpact, impactPercentage } = useVaultTvlImpact({
    tvl: vaultData?.tvl,
    asset: vaultData?.asset,
    amount,
  })

  const trackingParams = useVaultTrackingParams({
    address,
    network,
    name,
    asset,
    protocolName: vaultData?.metadata.protocol.name,
    liquidity: vaultData?.liquidity,
    tvl: vaultData?.tvl,
    rawYieldInToken,
  })

  const handleRedirectLinkEvent = () => {
    trackVaultButtonEvent({
      event: 'Go Button',
      params: trackingParams,
    })
  }

  const handleDetailsButtonEvent = () => {
    setDetailsOpen((prevState) => !prevState)
    trackVaultButtonEvent({
      event: 'Details Button',
      params: trackingParams,
    })
  }

  return (
    <Panel className="block p-0">
      <AccordionItem value={vault.name}>
        {topItem && !isHighTvlImpact && (
          <span className="flex min-h-8 items-center justify-center bg-green/60 px-4 py-2 text-center font-bold leading-none">
            Best APY
          </span>
        )}
        {isHighTvlImpact && (
          <HighTvlImpactBadge
            address={address}
            network={network}
            name={name}
            amount={amount}
            percentage={impactPercentage}
            symbol={asset.symbol}
          />
        )}
        <VaultItemContent>
          <VaultMainInfo
            address={address}
            network={network}
            name={name}
            asset={asset}
            tags={tags}
            trackingParams={trackingParams}
          />
          <VaultStatisticsWrapper>
            {isTablet ? (
              <VaultBasicStatistics vault={vault} period={period} isHighTvlImpact={isHighTvlImpact} />
            ) : (
              <VaultExpandedStatistics vault={vault} period={period} isHighTvlImpact={isHighTvlImpact} />
            )}
            {!isTablet && <VaultApyTile apyChartData={vault.chartData} apyMode={apyMode} />}
          </VaultStatisticsWrapper>
          <VaultControls
            isDetailsOpen={isDetailsOpen}
            lendLink={lendLink}
            handleLinkEvent={handleRedirectLinkEvent}
            handleDetailsEvent={handleDetailsButtonEvent}
          />
        </VaultItemContent>
        <AccordionContent duration="long">
          <VaultDetails
            vault={vault}
            vaultData={vaultData}
            period={period}
            isHighTvlImpact={isHighTvlImpact}
            apyMode={apyMode}
            isTablet={isTablet}
          />
        </AccordionContent>
      </AccordionItem>
    </Panel>
  )
}
