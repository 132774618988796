import aave from '/images/tokens/aave.svg'
import bal from '/images/tokens/bal.png'
import bat from '/images/tokens/bat.png'
import btc from '/images/tokens/btc.png'
import cbeth from '/images/tokens/cbeth.svg'
import comp from '/images/tokens/comp.png'
import crvusd from '/images/tokens/crvusd.png'
import dai from '/images/tokens/dai.png'
import dola from '/images/tokens/dola.png'
import eth from '/images/tokens/eth.svg'
import eura from '/images/tokens/eura.png'
import eurs from '/images/tokens/eurs.svg'
import eusd from '/images/tokens/eusd.png'
import ezeth from '/images/tokens/ezeth.png'
import frax from '/images/tokens/frax.png'
import frxeth from '/images/tokens/frxeth.png'
import gho from '/images/tokens/gho.png'
import gmx from '/images/tokens/gmx.svg'
import gusd from '/images/tokens/gusd.png'
import ldo from '/images/tokens/ldo.svg'
import link from '/images/tokens/link.png'
import lusd from '/images/tokens/lusd.png'
import mai from '/images/tokens/mai.png'
import matic from '/images/tokens/matic.png'
import mkusd from '/images/tokens/mkusd.png'
import ousg from '/images/tokens/ousg.png'
import pxeth from '/images/tokens/pxeth.png'
import pyusd from '/images/tokens/pyusd.png'
import rdnt from '/images/tokens/rdnt.svg'
import rep from '/images/tokens/rep.png'
import reth from '/images/tokens/reth.png'
import rseth from '/images/tokens/rseth.png'
import steth from '/images/tokens/steth.png'
import susd from '/images/tokens/susd.png'
import sushi from '/images/tokens/sushi.png'
import tusd from '/images/tokens/tusd.png'
import uni from '/images/tokens/uni.png'
import usda from '/images/tokens/usda.png'
import usdbc from '/images/tokens/usdbc.svg'
import usdc from '/images/tokens/usdc.png'
import usde from '/images/tokens/usde.png'
import usdm from '/images/tokens/usdm.svg'
import usdp from '/images/tokens/usdp.png'
import usdt from '/images/tokens/usdt.png'
import wbtc from '/images/tokens/wbtc.png'
import weeth from '/images/tokens/weeth.png'
import weeths from '/images/tokens/weeths.png'
import weth from '/images/tokens/weth.png'
import wmatic from '/images/tokens/wmatic.png'
import wsteth from '/images/tokens/wsteth.png'
import yfi from '/images/tokens/yfi.png'
import zrx from '/images/tokens/zrx.png'

export const getTokenImage = (symbol: string) => {
  switch (symbol.toLowerCase()) {
    case 'bal':
      return bal
    case 'btc':
    case 'btc.b':
      return btc
    case 'dai':
    case 'dai.e':
      return dai
    case 'dola':
      return dola
    case 'ezeth':
      return ezeth
    case 'frxeth':
      return frxeth
    case 'frax':
      return frax
    case 'pyusd':
      return pyusd
    case 'susd':
      return susd
    case 'sushi':
      return sushi
    case 'steth':
      return steth
    case 'usdc':
    case 'usdc.e':
      return usdc
    case 'usde':
      return usde
    case 'usdt':
    case 'usdt.e':
      return usdt
    case 'lusd':
      return lusd
    case 'wbtc':
      return wbtc
    case 'weth':
    case 'weth.e':
      return weth
    case 'weeth':
      return weeth
    case 'weeths':
      return weeths
    case 'wmatic':
      return wmatic
    case 'matic':
      return matic
    case 'aave':
      return aave
    case 'bat':
      return bat
    case 'comp':
      return comp
    case 'eth':
      return eth
    case 'reth':
      return reth
    case 'link':
      return link
    case 'rep':
      return rep
    case 'rseth':
      return rseth
    case 'tusd':
      return tusd
    case 'uni':
      return uni
    case 'usdp':
      return usdp
    case 'zrx':
      return zrx
    case 'yfi':
      return yfi
    case 'gusd':
      return gusd
    case 'usdbc':
      return usdbc
    case 'cbeth':
      return cbeth
    case 'eurs':
      return eurs
    case 'usdm':
      return usdm
    case 'crvusd':
      return crvusd
    case 'ousg':
      return ousg
    case 'pxeth':
      return pxeth
    case 'mkusd':
      return mkusd
    case 'wsteth':
      return wsteth
    case 'gho':
      return gho
    case 'rdnt':
      return rdnt
    case 'gmx':
      return gmx
    case 'ldo':
      return ldo
    case 'usda':
      return usda
    case 'mai':
      return mai
    case 'eura':
      return eura
    case 'eusd':
      return eusd
    default:
  }
}
